.thankYouContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 78vh;
  background: url('client/assets/images/banners/banner-05-cropped.webp') no-repeat center center/cover;
}

.thankYouContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Grey overlay with 50% opacity */
  z-index: 1;
}

.messageBox {
  background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent background for readability */
  padding: 40px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 90%;
  z-index: 2;
}

.messageBox h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.messageBox p {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 30px;
}


.messageBox {
  animation: fadeIn 1.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
