.defaultLink {
  color: white !important;
  text-decoration: none;
}

.scrolledLink {
  color: #555 !important;
  text-decoration: none;
}

.menuContainer {
  padding: 0 15px;
  display: flex;
  align-items: center;
}

.navWrapper {
  display: flex;
  align-items: center;
  gap: 0;
}

.langLink {
  text-align: center !important;
  font-weight: bold !important;
}

/* Desktop styles */
@media (min-width: 993px) {
  .navItem {
    color: white !important;
    padding: 8px 16px !important;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .navItemScrolled {
    color: #555 !important;
    text-decoration: none;
    padding: 8px 16px !important;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .separator {
    color: rgba(255, 255, 255, 0.5);
    padding: 0 4px;
    font-weight: 200;
    user-select: none;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
  }

  :global(.solid) .separator {
    color: rgba(0, 0, 0, 0.3);
  }

  :global(.dropdown-toggle) {
    color: inherit !important;
  }

  :global(.dropdown-menu) {
    margin-top: 0 !important;
    /* background-color: rgba(42, 140, 170, 0.8) !important; */
    background-color: white !important;
    border: none !important;
    border-radius: 4px;
    padding: 8px 0;
  }

  :global(.dropdown-item) {
    text-align: start !important;
    /* color: white !important; */
    /* color: #15558d !important; */
    padding: 8px 16px !important;
  }
}

/* Mobile styles */
@media (max-width: 992px) {
  .menuContainer {
    width: 100%;
    padding: 0;
  }

  .navWrapper {
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    /* background-color: #003B71; */
  }

  .menuCloseButton {
    color: white;
    padding: 15px 20px;
    text-align: right;
    border-bottom: 1px solid rgba(185, 33, 33, 0.2);
    cursor: pointer;
  }

  /* Basic menu item styles */
  .navItem {
    padding: .65rem 0px !important;
    color: white !important;
    width: 100%;
    /* text-align: left; */
  }

  /* Dropdown styles */
  :global(.dropdown) {
    /* background-color: #003B71; */
  }

  :global(.dropdown.show) {
    /* background-color: #002D57 !important; */
  }

  :global(.dropdown-toggle) {
    width: 100%;
    /* text-align: left; */
    /* padding: 15px 20px !important; */
    color: white !important;
    border: none !important;
    background-color: transparent !important;
  }

  :global(.dropdown-toggle)::after {
    float: right;
    margin-top: 8px;
  }

  :global(.dropdown-menu) {
    /* position: static !important; */
    width: 100% !important;
    /* margin: 0 !important; */
    /* padding: 0 !important; */
    /* border: none !important; */
    border-radius: 0 !important;
    /* background-color: #002D57 !important; */
  }

  :global(.dropdown-item) {
    padding: .25rem 1rem !important;
    color: white !important;
    /* background-color: transparent !important; */
    /* font-size: 16px; */
    /* text-align: left; */
  }

  /* Hover effects for dropdown items */
  :global(.dropdown-item:hover) {
    /* background-color: rgba(255, 255, 255, 0.1) !important; */
  }

  .separator {
    display: none;
  }
}

/* RTL Support */
[dir="rtl"] .navWrapper {
  flex-direction: column;
}

[dir="rtl"] :global(.dropdown-toggle) {
  text-align: right;
}

[dir="rtl"] :global(.dropdown-toggle)::after {
  /* float: left; */
  margin-right: 8px;
  margin-left: 0;
}