/* Header.module.css */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 5;
  background: transparent;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 1000;
  display: flex;
  align-items: center;
  padding: 0 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.transparent {
  background-color: transparent;
  color: #ffffff;
}

.solid {
  background-color: #ffffff;
  color: #333333;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.defaultLink {
  color: #ffffff;
  /* White for transparent background */
  transition: color 0.3s ease;
}

.scrolledLink {
  color: #000000;
  /* Dark for solid background */
  transition: color 0.3s ease;
}

/* Dropdown item styling to inherit colors */
.defaultLink .dropdown-item,
.scrolledLink .dropdown-item {
  color: inherit;
}

/* Header.module.css */

.logo-header {
  transition: opacity 0.3s ease;
}

.language-switcher {
  color: #f00;
}



/* Add to your Header.module.css */

/* Method 1: Using borders */
.navItem {
  position: relative;
  padding: 0 1.5rem;
}

.navItem:not(:last-child)::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 16px;
  /* Adjust height as needed */
  width: 1px;
  background-color: #e5e5e5;
  /* Light gray color - adjust as needed */
}

/* Method 2: Using pseudo-element with dots */
.navItemDots {
  position: relative;
  padding: 0 1.5rem;
}

.navItemDots:not(:last-child)::after {
  content: '•';
  position: absolute;
  right: -4px;
  top: 50%;
  transform: translateY(-50%);
  color: #e5e5e5;
  font-size: 12px;
}

/* Method 3: Using margin and background */
.navItemSpace {
  margin: 0 1px;
  padding: 0.5rem 1.5rem;
}

.navItemSpace:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

/* RTL Support */
[dir="rtl"] .navItem:not(:last-child)::after,
[dir="rtl"] .navItemDots:not(:last-child)::after {
  right: auto;
  left: 0;
}

[dir="rtl"] .navItemSpace:not(:last-child) {
  border-right: none;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

/* Hover state handling */
.navItem:hover,
.navItemDots:hover,
.navItemSpace:hover {
  background-color: rgba(0, 0, 0, 0.02);
}