#root {
    height: 100vh;
}

:root {
    --swiper-navigation-color: white;
}

/* Hero Section Enhancements */
.hero-section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: url('client/assets/images/banners/banner-05-cropped.webp') center/cover no-repeat;
}

.hero-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); /* Overlay with slight transparency */
    z-index: 1;
}

.hero-text {
    position: relative;
    z-index: 2;
    text-align: center;
    color: white;
    animation: fadeInUp 1s ease-in-out;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.hero-button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff; /* Change to a suitable color */
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    /* transition: background-color 0.3s; */
}

.hero-button:hover {
    background-color: #0056b3;
}
