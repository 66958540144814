* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: #fff;
}

.landing-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  padding-top: 50px;
}

.landing {
  position: relative;
  /* background-image: url("http://www.patrontour.com/wp-content/uploads/2018/02/cropped-437695-1.jpg"); */
  /* background-image: url("https://react-coming-soon.maksv.me/default-image.jpeg"); */
  background-image: url("../img/cover.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.landing h1 {
  font-size: 50px;
  color: #fff;
  font-family: "Courier New", Courier, monospace;
}

.landing h2 {
  color: #fff;
  font-family: "Courier New", Courier, monospace;
}
